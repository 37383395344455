import { useEffect, useState } from 'react';

import PlateContentsEditorDialog from 'client/app/components/Parameters/PlateContents/PlateContentsEditorDialog';
import PlateLayoutEditorDialog from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorDialog';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ProtocolStep } from 'common/types/Protocol';
import useDialog from 'common/ui/hooks/useDialog';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

/**
 * Handles the opening and closing of complex parameter editor dialogs.
 * Relies on triggers in worklfowBuilderStateContext. This does now allow
 * modifying the content of any of the dialogs, only the rendering of them.
 *
 * @returns Array of dialog JSX elements to render
 */
export function useComplexParameterEditorDialogManager() {
  // Currently when a dialog is triggered, then the state for additionalPanel is set
  // to be the value of the dialog to open. This was built to be used within the Builder
  // component. Here, we are re-using that logic to determine when to open one of the
  // complex parameter dialogs, because the underlying trigger components (i.e. buttons)
  // we are using in Protocols are the same as those in the Builder.
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);
  const dispatch = useWorkflowBuilderDispatch();

  const [plateContentsEditorDialog, openPlateContentsEditorDialog] = useDialog(
    PlateContentsEditorDialog,
  );
  const [plateLayoutEditorDialog, openPlateLayoutEditorDialog] = useDialog(
    PlateLayoutEditorDialog,
  );

  useEffect(() => {
    const resetPanel = () => {
      dispatch({ type: 'setAdditionalPanel', payload: undefined });
    };
    const handleOpenDialog = async () => {
      switch (additionalPanel) {
        case 'PlateContentsEditor':
          await openPlateContentsEditorDialog({});
          resetPanel();
          break;
        case 'PlateLayoutEditor':
          await openPlateLayoutEditorDialog({});
          resetPanel();
          break;
      }
    };

    void handleOpenDialog();
  }, [
    additionalPanel,
    dispatch,
    openPlateContentsEditorDialog,
    openPlateLayoutEditorDialog,
  ]);

  return [plateContentsEditorDialog, plateLayoutEditorDialog];
}

const PROTOCOL_SELECTED_STEP_ID_PARAM = 'selected_step';
const PROTOCOL_EXPANDED_LIST_PARAM = 'expand_input_list';

/**
 * Handles storing state in URL for parameters relating to the Protocols UI.
 *
 * @returns State setters for each URL param
 */
export function useProtocolsParamState(steps: ProtocolStep[]) {
  const [selectedStepId, setSelectedStepId] = useStateWithURLParams({
    paramName: PROTOCOL_SELECTED_STEP_ID_PARAM,
    paramType: 'string',
  });

  const [expandInputList, setExpandInputList] = useStateWithURLParams({
    paramName: PROTOCOL_EXPANDED_LIST_PARAM,
    paramType: 'boolean',
  });

  const handleSelectStep = (step: ProtocolStep) => {
    setSelectedStep(step);
    setSelectedStepId(step.id);
  };

  const [selectedStep, setSelectedStep] = useState(
    steps.find(step => step.id === selectedStepId) ?? steps[0],
  );

  const handleSetExpandInputList = () => {
    setExpandInputList(!expandInputList);
  };

  return {
    selectedStep,
    handleSelectStep,
    expandInputList,
    handleSetExpandInputList,
  };
}
