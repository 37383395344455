import React, { createContext, useContext, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import AutocompleteEditor from 'client/app/components/Parameters/AutocompleteEditor';
import CherryPickerEditor from 'client/app/components/Parameters/CherryPicker/CherryPickerEditor';
import ChromatographyActionsLayoutEditor from 'client/app/components/Parameters/ChromatographyActions/ChromatographyActionsLayoutEditor';
import GradientChromatographyActionsLayoutEditor from 'client/app/components/Parameters/ChromatographyActions/GradientChromatographyActionsLayoutEditor';
import DeckPositionsParameter from 'client/app/components/Parameters/DeckPositions/DeckPositionsParameter';
import DNAParameter from 'client/app/components/Parameters/DNA/DNAParameter';
import DOEDoubleMapEditor from 'client/app/components/Parameters/DOEDoubleMap/DOEDoubleMapEditor';
import DropdownEditor from 'client/app/components/Parameters/DropdownEditor';
import FileEditor from 'client/app/components/Parameters/FileEditor/Editor';
import FileSeriesEditor from 'client/app/components/Parameters/FileSeriesEditor';
import FiltrationPlateLayoutParameter from 'client/app/components/Parameters/FiltrationPlateLayout/FiltrationPlateLayoutParameter';
import FiltrationProtocolDesignParameter from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignParameter';
import LiquidAutocompleteEditor from 'client/app/components/Parameters/LiquidAutocompleteEditor';
import MapEditor from 'client/app/components/Parameters/MapEditor';
import { MapKeyValueType } from 'client/app/components/Parameters/MapEntryEditor';
import { PlateContentsSummaryParameter } from 'client/app/components/Parameters/PlateContents/PlateContentsSummaryParameter';
import PlateLayoutParameter from 'client/app/components/Parameters/PlateLayout/PlateLayoutParameter';
import PlateReaderProtocolParameter from 'client/app/components/Parameters/PlateReaderProtocol/PlateReaderProtocolParameter';
import ExistingPlateSelect from 'client/app/components/Parameters/PlateType/ExistingPlateSelect';
import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateTypeSelect } from 'client/app/components/Parameters/PlateType/PlateTypeSelect';
import PlateWasherProtocolDropdown from 'client/app/components/Parameters/PlateWasherProtocolDropdown';
import PolicyParameter from 'client/app/components/Parameters/Policy/PolicyParameter';
import RoboColumnLayoutEditor from 'client/app/components/Parameters/RoboColumnLayout/RoboColumnLayoutEditor';
import SimulationDetailsParameter from 'client/app/components/Parameters/Simulation/SimulationDetailsParameter';
import SimulationParameter from 'client/app/components/Parameters/Simulation/SimulationParameter';
import SpreadsheetCellEditor from 'client/app/components/Parameters/SpreadsheetCellEditor';
import WellCoordsEditor from 'client/app/components/Parameters/WellCoordsEditor';
import { getParameterDisplayDescription } from 'client/app/lib/workflow/elementConfigUtils';
import {
  AdditionalEditorProps,
  ArrayAdditionalProps,
  AutocompleteAdditionalProps,
  DropdownAdditionalProps,
  FileAdditionalProps,
  MapAdditionalProps,
  MeasurementAdditionalProps,
  PlateLayoutEditorAdditionalProps,
  PlateTypeEditorAdditionalProps,
  SpreadsheetAdditionalProps,
  TipTypeAdditionalProps,
  ToggleButtonsAdditionalProps,
  UnitAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { getAdditionalEditorPropsForEditorType } from 'common/elementConfiguration/getEditorTypeInfo';
import {
  getDefaultEditorForAnthaType,
  isArrayType,
} from 'common/elementConfiguration/parameterUtils';
import { Parameter, ParameterValue, WorkflowConfig } from 'common/types/bundle';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import ArrayEditor from 'common/ui/components/ParameterEditors/ArrayEditor';
import CheckBoxEditor from 'common/ui/components/ParameterEditors/CheckBoxEditor';
import ConnectionOnlyEditor from 'common/ui/components/ParameterEditors/ConnectionOnlyEditor';
import DOEMergeOptionDropdownEditor from 'common/ui/components/ParameterEditors/DOEMergeOptionDropdownEditor';
import FloatEditor from 'common/ui/components/ParameterEditors/FloatEditor';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import IntegerEditor from 'common/ui/components/ParameterEditors/IntegerEditor';
import PlateReaderShakingTypeDropdownEditor from 'common/ui/components/ParameterEditors/PlateReaderShakingTypeDropdownEditor';
import SpreadsheetEditor from 'common/ui/components/ParameterEditors/SpreadsheetEditor';
import StringArrayEditor from 'common/ui/components/ParameterEditors/StringArrayEditor';
import StringMeasurementEditor from 'common/ui/components/ParameterEditors/StringMeasurementEditor';
import TipTypeDropdownEditor from 'common/ui/components/ParameterEditors/TipTypeDropdownEditor';
import ToggleButtonsEditor from 'common/ui/components/ParameterEditors/ToggleButtonsEditor';
import ToggleEditor from 'common/ui/components/ParameterEditors/ToggleEditor';
import Dropdown from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { theme } from 'common/ui/theme';

type Props = {
  anthaType: string;
  /**
   * For DOE double maps, we need the original parameter so that the DoubleMap editor can
   * tailor the copy and inputs.
   */
  parameter?: Parameter;
  onChange: (param: ParameterValue, instanceName?: string) => void;
  onPendingChange?: (param: ParameterValue, instanceName?: string) => void;
  workflowId?: string;
  workflowName?: string;
  instanceName?: string;
  /** Optionally provide an editor type from a parameter configuration */
  editorType?: EditorType;
  editorProps?: AdditionalEditorProps;
  /**
   * Certain types of editors need to know about parts of the workflow config in order to work.
   * For example, the picker of plate reader protocol needs to know which plate reader was
   * selected in the workflow config. This adds complexity but is needed for good UX.
   */
  workflowConfig?: WorkflowConfig;
  onBlur?: any;
  mapKeyValueType?: MapKeyValueType;
} & ParameterEditorBaseProps<ParameterValue>;

const ParameterEditor = React.memo(function ParameterEditor(
  props: Omit<Props, 'keyValue' | 'index'>,
) {
  /**
   * Some parameter lists won't provide an editor type from a configuration, so fall back
   * to the default in that case.
   */
  const editorType =
    props.editorType ?? getDefaultEditorForAnthaType(props.anthaType) ?? null;

  const editorProps = useMemo(() => {
    if (props.editorProps) {
      return props.editorProps;
    }
    return editorType
      ? getAdditionalEditorPropsForEditorType(editorType, props.anthaType)
      : undefined;
  }, [editorType, props.anthaType, props.editorProps]);

  switch (editorType) {
    case EditorType.ARRAY: {
      const { itemEditor, overrideAddNewItemCopy, onItemDelete } =
        editorProps as ArrayAdditionalProps;
      return (
        <ArrayEditor
          component={ParameterEditor}
          anthaType={props.anthaType}
          value={props.value || []}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          itemEditorProps={itemEditor}
          overrideAddNewItemCopy={overrideAddNewItemCopy}
          onItemDelete={onItemDelete}
          instanceName={props.instanceName}
        />
      );
    }
    case EditorType.AUTOCOMPLETE: {
      const {
        canAcceptCustomValues,
        provideDefaultKey,
        useDynamicOptions,
        staticOptions,
        anthaTypeOverride,
        additionalSourceTypes,
      } = editorProps as AutocompleteAdditionalProps;

      return (
        <AutocompleteEditor
          anthaType={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          isDisabled={props.isDisabled}
          disableClearable={props.mapKeyValueType === 'MAP_KEY'}
          placeholder={props.placeholder}
          staticOptions={staticOptions}
          useDynamicOptions={useDynamicOptions}
          anthaTypeOverride={anthaTypeOverride}
          additionalSourceTypes={additionalSourceTypes}
          canAcceptCustomValues={canAcceptCustomValues}
          provideDefaultKey={provideDefaultKey}
        />
      );
    }
    case EditorType.CONNECTION_ONLY:
      return <ConnectionOnlyEditor isDisabled={props.isDisabled} />;
    case EditorType.CHECKBOX:
      return (
        <CheckBoxEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.DOE_MERGE_OPTION:
      return (
        <DOEMergeOptionDropdownEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.DNA:
      return (
        <DNAParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.DROPDOWN: {
      const { options, useDynamicOptions } = editorProps as DropdownAdditionalProps;
      return (
        <DropdownEditor
          value={props.value}
          anthaType={props.anthaType}
          useDynamicOptions={useDynamicOptions}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          options={options}
          placeholder={props.placeholder}
        />
      );
    }
    case EditorType.FILE: {
      const { template } = editorProps as FileAdditionalProps;
      return (
        <FileEditor
          value={props.value}
          template={template}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    }
    case EditorType.FILE_SERIES:
      return (
        <FileSeriesEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.FLOAT:
      return (
        <FloatEditor
          type={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
        />
      );
    case EditorType.SIMULATION:
      return (
        // TODO This parameter can go once all workflows using it
        // are migrated to the newer `SimulationDetailsParameter` (T449)
        <SimulationParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.SIMULATION_RESULTS:
      return (
        <SimulationDetailsParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.INT:
      return (
        <IntegerEditor
          type={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
        />
      );
    case EditorType.LIQUID:
      return (
        <LiquidAutocompleteEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.MEASUREMENT: {
      // for historic reasons measurements were serialised as string hence we
      // still use the StringMeasurementEditor
      const { units, defaultUnit } = editorProps as MeasurementAdditionalProps;
      return (
        <StringMeasurementEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          units={units}
          defaultUnit={defaultUnit}
          placeholder={props.placeholder}
        />
      );
    }
    case EditorType.PLATE_READER_ABSORBANCE_PROTOCOL:
      return (
        <PlateReaderProtocolParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          workflowId={props.workflowId}
          workflowConfig={props.workflowConfig}
          protocolType="PlateReaderAbsorbanceProtocol"
        />
      );
    case EditorType.PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL:
      return (
        <PlateReaderProtocolParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          workflowId={props.workflowId}
          workflowConfig={props.workflowConfig}
          protocolType="PlateReaderAbsorbanceSpectraProtocol"
        />
      );
    case EditorType.PLATE_READER_FLUORESCENCE_PROTOCOL:
      return (
        <PlateReaderProtocolParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          workflowId={props.workflowId}
          workflowConfig={props.workflowConfig}
          protocolType="PlateReaderFluorescenceProtocol"
        />
      );
    case EditorType.PLATE_READER_LUMINESCENCE_PROTOCOL:
      return (
        <PlateReaderProtocolParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          workflowId={props.workflowId}
          workflowConfig={props.workflowConfig}
          protocolType="PlateReaderLuminescenceProtocol"
        />
      );
    case EditorType.PLATE_WASHER_PROTOCOL:
      return (
        <PlateWasherProtocolDropdown
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          workflowConfig={props.workflowConfig}
        />
      );
    case EditorType.MAP:
    case EditorType.PLATE_CONTENTS: {
      const { keyEditor, valueEditor, inline, disableKeys, overrideAddNewEntryCopy } =
        (editorProps as MapAdditionalProps) ?? {};
      return (
        <MapEditor
          anthaType={props.anthaType}
          value={props.value || {}}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          instanceName={props.instanceName}
          keyEditorProps={keyEditor}
          valueEditorProps={valueEditor}
          inline={inline}
          disableKeys={disableKeys}
          overrideAddNewEntryCopy={overrideAddNewEntryCopy}
        />
      );
    }
    case EditorType.DOE_MIX_RULES:
      return (
        <DOEDoubleMapEditor
          parameter={props.parameter}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          instanceName={props.instanceName}
        />
      );
    case EditorType.MULTI_FILE:
      // Currently unused. TODO: Update AddFileButton to accept multiple uploads.
      return null;
    case EditorType.PLATE_TYPE: {
      const isWorkflowSettingsPanel = (
        editorProps as PlateTypeEditorAdditionalProps | null
      )?.isWorkflowSettingsPanel;
      return (
        <PlateSelectionEditor
          value={props.value || ''}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          isWorkflowSettingsPanel={isWorkflowSettingsPanel}
          plateSelectors={[
            { Component: PlateTypeSelect, additionalProps: { isWorkflowSettingsPanel } },
          ]}
        />
      );
    }
    case EditorType.PLATE:
      return (
        <PlateSelectionEditor
          value={props.value || ''}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          plateSelectors={[
            PlateTypeSelect,
            {
              Component: ExistingPlateSelect,
              additionalProps: { value: props.value || '' },
            },
          ]}
        />
      );
    case EditorType.EXISTING_PLATE:
      return (
        <PlateSelectionEditor
          value={props.value || ''}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          plateSelectors={[
            {
              Component: ExistingPlateSelect,
              additionalProps: { value: props.value || '' },
            },
          ]}
        />
      );
    case EditorType.POLICY:
      return (
        <PolicyParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.STRING:
      return (
        <GenericInputEditor
          type={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
        />
      );
    case EditorType.STRING_ARRAY:
      return (
        <StringArrayEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
          instanceName={props.instanceName}
        />
      );
    case EditorType.SPREADSHEET: {
      return (
        <SpreadsheetEditor
          shouldReturnArray={isArrayType(props.anthaType)}
          dialogTitle={
            props.parameter?.configuration?.displayName ?? props.parameter?.name
          }
          dialogInfo={
            props.parameter ? getParameterDisplayDescription(props.parameter) : undefined
          }
          buttonTitle={(editorProps as SpreadsheetAdditionalProps).buttonTitle}
          value={props.value}
          onChange={props.onChange}
          // For propagating new values to the autocomplete context without
          // persisting the changes before the user confirms.
          onPendingChange={props.onPendingChange}
          isDisabled={props.isDisabled}
          configuration={editorProps as SpreadsheetAdditionalProps}
          cellEditorComponent={SpreadsheetCellEditor}
          workflowName={props.workflowName}
        />
      );
    }
    case EditorType.TOGGLE:
      return (
        <ToggleEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.TOGGLE_BUTTONS: {
      const { options } = editorProps as ToggleButtonsAdditionalProps;
      return (
        <ToggleButtonsEditor
          value={props.value}
          onChange={props.onChange}
          options={options}
          disabled={props.isDisabled}
        />
      );
    }
    case EditorType.TIP_TYPE: {
      const { supportedTipTypes } = (editorProps as TipTypeAdditionalProps) ?? {};
      return (
        <TipTypeDropdownEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          supportedTipTypes={supportedTipTypes ?? []}
        />
      );
    }
    case EditorType.CHERRY_PICK:
      return (
        <CherryPickerEditor
          onChange={props.onChange}
          value={props.value}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.PLATE_READER_SHAKING_TYPE:
      return (
        <PlateReaderShakingTypeDropdownEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.WELL_COORDS:
      return (
        <WellCoordsEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.UNIT: {
      const { units } = editorProps as UnitAdditionalProps;
      const options = units.map(value => ({ label: value, value }));
      return (
        <Dropdown
          valueLabel={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          options={options}
          placeholder={props.placeholder}
        />
      );
    }
    case EditorType.ROBOCOLUMN_LAYOUT_MAP:
      return (
        <RoboColumnLayoutEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
    case EditorType.CHROMATOGRAPHY_ACTIONS_MAP:
      return (
        <ChromatographyActionsLayoutEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          instanceName={props.instanceName}
        />
      );
    case EditorType.GRADIENT_CHROMATOGRAPHY_ACTIONS_MAP:
      return (
        <GradientChromatographyActionsLayoutEditor
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          instanceName={props.instanceName}
        />
      );
    case EditorType.PLATE_CONTENTS_SUMMARY:
      return (
        <PlateContentsSummaryParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          instanceName={props.instanceName}
        />
      );
    case EditorType.DECK_POSITIONS:
      return <DeckPositionsParameter value={props.value} />;
    case EditorType.PLATE_LAYOUT:
      return <FiltrationPlateLayoutParameter value={props.value} />;
    case EditorType.FILTER_PLATE_PROTOCOL_DESIGN:
      return <FiltrationProtocolDesignParameter value={props.value} />;
    case EditorType.PLATE_LAYOUT_LAYERS:
      return (
        <PlateLayoutParameter
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          instanceName={props.instanceName}
          configuration={props.editorProps as PlateLayoutEditorAdditionalProps}
        />
      );

    default:
      // TODO: Migrate this to a raw JSON editor when it's ready
      return (
        <GenericInputEditor
          type={props.anthaType}
          value={props.value}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      );
  }
});

export type ParameterEditorHelperText = {
  type: 'error' | 'warning';
  message: string;
};

export default function ParameterEditorWithHelperText(
  props: Omit<Props, 'hasError'> & {
    helperText?: ParameterEditorHelperText;
  },
) {
  const classes = useStyles();

  const editor = (
    <>
      <ParameterEditor
        value={props.value}
        anthaType={props.anthaType}
        parameter={props.parameter}
        onChange={props.onChange}
        onPendingChange={props.onPendingChange}
        instanceName={props.instanceName}
        isDisabled={props.isDisabled}
        placeholder={props.placeholder}
        workflowId={props.workflowId}
        workflowName={props.workflowName}
        editorType={props.editorType}
        editorProps={props.editorProps}
        workflowConfig={props.workflowConfig}
        onBlur={props.onBlur}
        mapKeyValueType={props.mapKeyValueType}
        hasError={props.helperText?.type === 'error'}
      />
      {props.helperText && (
        <Box mt={1}>
          <Typography
            variant="caption"
            className={cx({
              [classes.error]: props.helperText.type === 'error',
              [classes.warning]: props.helperText.type === 'warning',
            })}
          >
            {props.helperText.message}
          </Typography>
        </Box>
      )}
    </>
  );

  return props.parameter ? (
    <ParameterContext.Provider value={props.parameter}>
      {editor}
    </ParameterContext.Provider>
  ) : (
    editor
  );
}

const useStyles = makeStylesHook({
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.dark,
  },
});

const ParameterContext = createContext<Parameter | undefined>(undefined);

export const useParameterContext = () => useContext(ParameterContext);
