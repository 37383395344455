import { useEffect, useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { QUERY_PROTOCOL_INSTANCE } from 'client/app/api/gql/queries';
import { deserialiseWorkflowResponse } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { ProtocolInstanceQuery } from 'client/app/gql';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { getElementId, getElementParameterName } from 'common/types/schema';

// TODO - Remove this once we implement query for all protocols on ProtocolScreen.
export const MockProtocols: { name: string; id: string }[] = [
  { name: 'DC Protein Quantification Assay', id: '6a3602b8-04c0-4378-8e64-db2dad05249b' },
  { name: 'Mycoplasma Luminescence Assay', id: 'ae11bc81-5823-47f0-89ef-2b855258ecb0' },
];

type QueryProtocolResult =
  | { status: 'error'; error: ApolloError | Error }
  | { status: 'protocol-instance-loading' }
  | {
      status: 'parameters-loading';
      // While parameters are loading, we can start to render the protocolInstance
      // which is why it is returned with this result.
      protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']>;
    }
  | {
      status: 'success';
      protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']>;
    };

/**
 * Query the ProtocolInstnace and converts the underlying worklfow from the
 * Protocol into the workflowState to populate WorkflowBuilderStateContext,
 * but uses the specified parameter values in the WorkflowSchema to override
 * the default values.
 */
export const useGetDataAndInitialiseState = (
  protocolInstanceId: ProtocolInstanceId,
): QueryProtocolResult => {
  const dispatch = useWorkflowBuilderDispatch();
  const fetchGraphQLElementSet = useFetchGraphQLElementSet();

  const {
    data: protocolInstanceData,
    loading: protocolInstanceLoading,
    error: protocolInstanceLoadingError,
  } = useQuery(QUERY_PROTOCOL_INSTANCE, {
    variables: { id: protocolInstanceId },
  });

  const [parametersLoading, setParametersLoading] = useState(false);
  const [parametersLoadingError, setParametersLoadingError] = useState<Error>();

  useEffect(() => {
    (async () => {
      try {
        if (!protocolInstanceData) {
          return;
        }
        setParametersLoading(true);
        const workflow = protocolInstanceData?.protocolInstance.protocol.workflow;
        const elementSet = await fetchGraphQLElementSet(workflow?.id);
        const { workflowState } = deserialiseWorkflowResponse(workflow, elementSet);

        // Here we take the parameters from workflowState, which stores all the parameter
        // values indexed by element instance name, and we update the values of those parameters
        // using the default value as provided by the MockWorkflowSchema.
        const copyParameters = { ...workflowState.parameters };

        workflow.workflow.Schema?.inputs?.forEach(input => {
          const elementInstanceId = getElementId(input.path);
          const parameterName = getElementParameterName(input.path);
          const elementInstanceName = workflowState.elementInstances.find(
            elementInstance => elementInstance.Id === elementInstanceId,
          )?.name;
          if (elementInstanceName && parameterName) {
            copyParameters[elementInstanceName][parameterName] = input.default;
          }
        });

        dispatch({
          type: 'resetToWorkflow',
          payload: { ...workflowState, parameters: copyParameters },
        });
      } catch (err) {
        setParametersLoadingError(err);
      } finally {
        setParametersLoading(false);
      }
    })();
  }, [dispatch, fetchGraphQLElementSet, protocolInstanceData]);

  if (protocolInstanceLoading) {
    return { status: 'protocol-instance-loading' };
  } else if (
    protocolInstanceLoadingError ||
    parametersLoadingError ||
    !protocolInstanceData?.protocolInstance
  ) {
    return {
      status: 'error',
      error:
        protocolInstanceLoadingError ||
        parametersLoadingError ||
        new Error('Protocol instance data not found'),
    };
  } else if (parametersLoading) {
    return {
      status: 'parameters-loading',
      protocolInstance: protocolInstanceData.protocolInstance,
    };
  }
  return {
    status: 'success',
    protocolInstance: protocolInstanceData.protocolInstance,
  };
};
