import React, { useState } from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import {
  flowRateToResidenceTime,
  flowVelocityToResidenceTime,
  residenceTimeToFlowRate,
  residenceTimeToFlowVelocity,
} from 'common/lib/chromatography';
import { Measurement } from 'common/types/mix';
import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';

const RESIDENCE_TIME_UNITS = ['s', 'min'];
const FLOW_RATE_UNITS = ['ul/s'];
const FLOW_VELOCITY_UNITS = ['cm/h'];

type ResidenceTimeInputsProps = {
  isDisabled?: boolean;
  robocolumnVolume?: Measurement;
  residenceTime?: Measurement;
  setResidenceTime: (newResidenceTime?: Measurement) => void;
};

/**
 * Inputs for setting residence time for a given chromatography run. Allows input as
 * residence time, flow rate, or flow velocity. When the user changes one input, the
 * others will automatically be computed.
 */
export function ResidenceTimeInputs({
  isDisabled,
  robocolumnVolume,
  residenceTime,
  setResidenceTime,
}: ResidenceTimeInputsProps) {
  const [flowRate, setFlowRate] = useState<Measurement | undefined>(() =>
    residenceTimeToFlowRate(residenceTime, robocolumnVolume),
  );
  const [flowVelocity, setFlowVelocity] = useState<Measurement | undefined>(() =>
    residenceTimeToFlowVelocity(residenceTime, robocolumnVolume),
  );

  // Calculations will result in floating point imprecision. robocolumn
  // supporting devices are capable of flow rates ranging from ~0.1 to ~1000
  // ul/s. Given currently manufactured robocolumns have volumes of 600 to 100
  // ul, then the minimum residence time is ~0.6 s.
  //
  // So generally rounding values to 1 dp is fine. Since we don't convert from
  // min to seconds but keep the value and just change the unit, 1 dp for min
  // times are also reasonable at the scale of mins
  //
  // Moreover, we set rounded residence times as this is useful for the
  // colouring algorithm comparisons of residence time in the parent component
  const setRoundedResidenceTime = (m?: Measurement) => setResidenceTime(round1Digit(m));
  const setRoundedFlowVelocity = (m?: Measurement) => setFlowVelocity(round1Digit(m));
  const setRoundedFlowRate = (m?: Measurement) => setFlowRate(round1Digit(m));

  // When user enters a residence time, update the flow rate and flow velocity.
  const handleResidenceTimeChange = (newResidenceTime?: Measurement) => {
    setRoundedResidenceTime(newResidenceTime);
    setRoundedFlowRate(residenceTimeToFlowRate(newResidenceTime, robocolumnVolume));
    setRoundedFlowVelocity(
      residenceTimeToFlowVelocity(newResidenceTime, robocolumnVolume),
    );
  };

  // When user enters a flow rate, update the residence time and flow velocity
  const handleFlowRateChange = (newFlowRate?: Measurement) => {
    const newResidenceTime = flowRateToResidenceTime(newFlowRate, robocolumnVolume);
    const newFlowVelocity = residenceTimeToFlowVelocity(
      newResidenceTime,
      robocolumnVolume,
    );
    setRoundedFlowRate(newFlowRate);
    setRoundedResidenceTime(newResidenceTime);
    setRoundedFlowVelocity(newFlowVelocity);
  };

  // When user enters a flow rate, update the residence time and flow rate
  const handleFlowVelocityChange = (newFlowVelocity?: Measurement) => {
    const newResidenceTime = flowVelocityToResidenceTime(
      newFlowVelocity,
      robocolumnVolume,
    );
    const newFlowRate = residenceTimeToFlowRate(newResidenceTime, robocolumnVolume);
    setRoundedFlowVelocity(newFlowVelocity);
    setRoundedResidenceTime(newResidenceTime);
    setRoundedFlowRate(newFlowRate);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">Loading Time</Typography>
        <InlineHelp>
          <p>
            The following calculations are automatically performed for you:
            <ul>
              <li>Residence time (s) = robocolumn volume (l) ÷ flow rate (l/s)</li>
              <li>
                Residence time (s) = robocolumn volume (l) ÷ (flow velocity (m/s) ×
                robocolumn area (m²))
              </li>
              <li>Flow rate (l/s) = robocolumn volume (l) ÷ residence time (s)</li>
              <li>
                Flow velocity (m/s) = (robocolumn volume (l) ÷ residence time (s)) ÷
                robocolumn area (m²)
              </li>
            </ul>
          </p>
        </InlineHelp>
      </Box>
      <Box>
        <InputLabel shrink>Residence Time</InputLabel>
        <MeasurementEditor
          validUnits={RESIDENCE_TIME_UNITS}
          defaultUnit={RESIDENCE_TIME_UNITS[0]}
          value={residenceTime}
          onChange={handleResidenceTimeChange}
          isDisabled={isDisabled}
          isRequired
        />
      </Box>
      {robocolumnVolume ? (
        <>
          <Box>
            <InputLabel shrink>Volumetric Flow Rate</InputLabel>
            <MeasurementEditor
              value={flowRate}
              validUnits={FLOW_RATE_UNITS}
              defaultUnit={FLOW_RATE_UNITS[0]}
              onChange={handleFlowRateChange}
              isDisabled={isDisabled || !robocolumnVolume}
              isRequired
            />
          </Box>
          <Box>
            <InputLabel shrink>Flow Velocity</InputLabel>
            <MeasurementEditor
              value={flowVelocity}
              validUnits={FLOW_VELOCITY_UNITS}
              defaultUnit={FLOW_VELOCITY_UNITS[0]}
              onChange={handleFlowVelocityChange}
              isDisabled={isDisabled}
              isRequired
            />
          </Box>
        </>
      ) : (
        <FormHelperText disabled>
          Cannot auto-calculate flow rate or velocity since multiple RoboColumns selected
          with different column volumes.
        </FormHelperText>
      )}
    </>
  );
}

function round1Digit(measurement?: Measurement): Measurement | undefined {
  if (!measurement) {
    return;
  }
  return { value: +measurement.value.toFixed(1), unit: measurement.unit };
}
